// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ebAlLVTr0"];

const serializationHash = "framer-rNno1"

const variantClassNames = {ebAlLVTr0: "framer-v-11uqzr8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, newTab, title, width, ...props}) => { return {...props, OKWoTyVeb: title ?? props.OKWoTyVeb ?? "Get started", sMUGBDnG6: newTab ?? props.sMUGBDnG6, ZxUlBMk7Y: link ?? props.ZxUlBMk7Y} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, OKWoTyVeb, ZxUlBMk7Y, sMUGBDnG6, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ebAlLVTr0", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rNno1", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={ZxUlBMk7Y} openInNewTab={sMUGBDnG6}><motion.a {...restProps} className={`${cx("framer-11uqzr8", className)} framer-10npnva`} data-framer-name={"Primary"} layoutDependency={layoutDependency} layoutId={"ebAlLVTr0"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-font-weight": "700", "--framer-line-height": "27px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-1b16e4e2-8780-4afe-aa5a-21604c3aa764, rgb(0, 0, 0)))"}}>Get started</motion.p></React.Fragment>} className={"framer-130lssr"} data-framer-name={"Subscribe now"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"I87:318;87:276"} style={{"--extracted-r6o4lv": "var(--token-1b16e4e2-8780-4afe-aa5a-21604c3aa764, rgb(0, 0, 0))", "--framer-paragraph-spacing": "0px"}} text={OKWoTyVeb} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rNno1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rNno1 .framer-10npnva { display: block; }", ".framer-rNno1 .framer-11uqzr8 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: visible; padding: 0px 10px 0px 10px; position: relative; text-decoration: none; width: min-content; }", ".framer-rNno1 .framer-130lssr { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rNno1 .framer-11uqzr8 { gap: 0px; } .framer-rNno1 .framer-11uqzr8 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-rNno1 .framer-11uqzr8 > :first-child { margin-top: 0px; } .framer-rNno1 .framer-11uqzr8 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 92.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"OKWoTyVeb":"title","ZxUlBMk7Y":"link","sMUGBDnG6":"newTab"}
 * @framerImmutableVariables true
 */
const FramerZNrsG7nAL: React.ComponentType<Props> = withCSS(Component, css, "framer-rNno1") as typeof Component;
export default FramerZNrsG7nAL;

FramerZNrsG7nAL.displayName = "UI — Button | Primary";

FramerZNrsG7nAL.defaultProps = {height: 32, width: 92.5};

addPropertyControls(FramerZNrsG7nAL, {OKWoTyVeb: {defaultValue: "Get started", displayTextArea: false, title: "Title", type: ControlType.String}, ZxUlBMk7Y: {title: "Link", type: ControlType.Link}, sMUGBDnG6: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerZNrsG7nAL, [])